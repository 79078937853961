export default [
  {id: 1, name: 'Executive Board'},
  {id: 15, name: 'Advisory Board'},
  // { id: 2, name: 'Advisory Committee' },
  {id: 3, name: 'Election and Finance Committees'},
  {id: 4, name: 'EFRS CEO'},
  {id: 13, name: 'EFRS Administrator'}, // porque ja existem dados para os outros ids e o front renderiza pela ordem deste array
  {id: 5, name: 'Educational Wing Management Team'},
  //{ id: 6, name: 'Expert Committees' },
  {id: 7, name: 'Medical Imaging Committee'},
  {id: 8, name: 'RT Committee'},
  {id: 9, name: 'NM Committee'},
  {id: 12, name: 'Research Committee'},
  {id: 14, name: 'EFRS Research Hub'},
  {id: 16, name: 'EFRS/FORCE Committee'},
  {id: 10, name: 'Project Working Groups'},
  {id: 11, name: 'EFRS Expert Network'}
]
